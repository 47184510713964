<template>
  <div>
    <div class="fixed-top">
      <theheader></theheader>
    </div>

    <div class="section-v white-bg" style="height: 100%;">
      <getstarted-home
        subcaption=""
        maincap2="MatFavor"
        maincap4="数智化材料建模系统"
        maincap8="材料建模 · 一触即达"
        maincap10="材料卡片开发效率较传统方法提升"
        maincap11="10倍"
        btntext="申请试用"
        illustration="images/matfavor-min.png"
      ></getstarted-home>
    </div>

    <div class="section white-bg" style="height: 100%;">
      <div class="pt-2">
        <b-container>
          <subtitle
            data-aos="fade-up"
            class="deepblue"
            subtitle="核心功能"
          ></subtitle>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-xl-2 pb-2">
        <separator data-aos="fade-left"></separator>
      </div>

      <b-container>
        <detailing
          class="pt-xl-3 d-none d-lg-block deepblue"
          :detailing="funDetails"
        >
        </detailing>
      </b-container>

      <b-container class="card-container">
        <div class="row p-md-4 py-2 justify-content-md-center">
          <div
            class="col-xl-3 col-lg-12 col-md-12 col-12"
            v-for="item in cardDetails"
            :key="item.label"
          >
            <router-link to="/product" class="hvr-grow">
              <svgcards
                data-aos="fade-up"
                :cardIcon="item.icon"
                :cardTitle="item.label"
                :cardDetails="item.desc"
              ></svgcards>
            </router-link>
          </div>
        </div>
      </b-container>
    </div>

    <div class="section white-bg py-xl-5 py-md-3 py-2" style="height: 100%">
      <b-container fluid>
        <div class="py-xl-2">
          <b-container>
            <subtitle
              data-aos="fade-up"
              class="deepblue"
              subtitle="为何选择MatFavor？"
            ></subtitle>
          </b-container>
        </div>

        <div class="d-flex justify-content-center">
          <separator data-aos="fade-right"></separator>
        </div>

        <b-container>
          <detailing
            data-aos="fade-up"
            class="pt-xl-3 d-none d-lg-block deepblue py-2"
            :detailing="seleDetails"
          >
          </detailing>
        </b-container>

        <b-container>
          <div class="d-flex justify-content-center mb-lg-5 pb-lg-5 py-3">
            <div class="row" style="width: 100%">
              <div class="col-xl-6 col-lg-12 col-md-12 col-12 mt-2 py-3">
                <tupleimg1 imageOne="jingdu-black.svg"></tupleimg1>
              </div>

              <div class="col-xl-6 col-lg-12 col-md-12 col-12">
                <div class="mt-2">
                  <b-container class="p-0 m-0">
                    <div class="p-0 m-0">
                      <subtitle
                        data-aos="fade-right"
                        class="text-left deepblue px-1"
                        subtitle="自主核心技术"
                      ></subtitle>
                    </div>
                  </b-container>

                  <b-container>
                    <circledetailing
                      data-aos="fade-left"
                      class="px-3 py-0 my-0 text-left deepblue"
                      :detailing="circleDetail"
                    />
                  </b-container>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </b-container>
    </div>

    <div class="section">
      <thefooter></thefooter>
    </div>

    <getstartedModal></getstartedModal>

    <div class="theToggle">
      <toogle></toogle>
    </div>
  </div>
</template>

<script>
import svgcards from '../components/cards/svgcards';
import thefooter from '../components/elements/footer.vue';
import getstartedModal from '../components/elements/subscribe.vue';
import theheader from '../components/elements/theheader.vue';
import toogle from '../components/elements/toggleMob.vue';
import tupleimg1 from '../components/images/tupleimg1';
import getstartedHome from '../components/landing/getstartedHome.vue';
import circledetailing from '../components/typography/circledetailing';
import detailing from '../components/typography/detailing.vue';
import separator from '../components/typography/separator.vue';
import subtitle from '../components/typography/subtitle.vue';

export default {
  name: 'home',
  data() {
    return {
      funDetails:
        '覆盖材料开发主要工作场景，包含动态测试数据处理、智能材料卡片开发、特殊材料模型求解器、材料卡片数据库、材料卡片精度评分以及材料建模工具集，提供材料建模、选材分析及仿真求解的一站式解决方案。',
      seleDetails:
        '集成材料建模、仿真和智能选材功能的工业仿真软件，打破国外同类软件依赖；创建新型四维材料模型，与国外竞品相比保证精度相当的情况下计算速度提升15%；突破材料模型高效递进式自动标定技术，材料卡片开发效率较传统方法提升10倍',
      cardDetails: [
        {
          icon: 'matdata.svg',
          label: 'MatData',
          desc: '动态测试数据处理\n准静态、高应变速率',
        },
        {
          icon: 'matcard.svg',
          label: 'MatCard',
          desc: '智能材料卡片开发\n自动化标定、快且准',
        },
        {
          icon: 'matSolver.svg',
          label: 'MatSolver',
          desc: '特殊材料模型求解器\n自研本构、高效求解',
        },
        {
          icon: 'matDB.svg',
          label: 'MatDB',
          desc: '材料卡片数据库\n数据管理、智能选材',
        },

        {
          icon: 'matcheck.svg',
          label: 'MatCheck',
          desc: '材料卡片精度评分\n精度检测、量化评分',
        },
        {
          icon: 'mattools.svg',
          label: 'MatTools',
          desc: '材料建模工具集\n高效精准、灵活便捷',
        },
      ],
      circleDetail:
        '硬化曲线多元非线性回归技术\n粘塑性曲线全局优化技术\n宽应变率范围本构模型\n宽温域本构模型\n复杂应力状态本构模型\n屈服各向异性本构模型\n零部件全生命周期损伤映射技术',
    };
  },
  components: {
    getstartedHome,
    theheader,
    toogle,
    svgcards,
    subtitle,
    circledetailing,
    detailing,
    separator,
    tupleimg1,
    thefooter,
    getstartedModal,
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .card-container {
    max-width: 1240px;
  }
}
</style>

<template>
  <div>
    <div class="fixed-top">
      <theheader></theheader>
    </div>

    <!--    <div class="section white-bg py-xl-5 mt-5">-->
    <!--    <div class="p-xl-0 pt-2">-->
    <!--      <b-container>-->
    <!--        <subtitle class="deepblue" data-aos="fade-up" subtitle="产品亮点"></subtitle>-->
    <!--      </b-container>-->
    <!--    </div>-->

    <!--    <div class="d-flex justify-content-center py-xl-2">-->
    <!--      <separatormedium data-aos="fade-right"></separatormedium >-->
    <!--    </div>-->

    <!--    <b-container>-->
    <!--      <detailing class="pt-3 px-4 text-center deepblue" data-aos="fade-up" detailing="概况性描述。。。.">-->
    <!--      </detailing>-->
    <!--    </b-container>-->

    <!--    <b-container>-->
    <!--      <div class="p-2 m-2">-->
    <!--        <div class="row">-->
    <!--          <div class="col-6">-->
    <!--            <roundrecimg class="p-5 lightblue-bg"  data-aos="fade-up" image="qufu.png"></roundrecimg>-->
    <!--          </div>-->
    <!--          <div class="col-6">-->
    <!--            <roundrecimg class="p-5 lightblue-bg"  data-aos="fade-up" image="home-yeild.png"></roundrecimg>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="row py-3 my-2">-->
    <!--          <div class="col-6">-->
    <!--            <roundrecimg class="p-5 lightblue-bg"  data-aos="fade-right" image="home-mmc.png"></roundrecimg>-->
    <!--          </div>-->
    <!--          <div class="col-6">-->
    <!--            <roundrecimg class="p-5 lightblue-bg"  data-aos="fade-right" image="home-hard3.png"></roundrecimg>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <div class="row py-2 my-2">-->
    <!--          <div class="col-6">-->
    <!--            <roundrecimg class="p-5 lightblue-bg"></roundrecimg>-->
    <!--          </div>-->
    <!--          <div class="col-6">-->
    <!--            <roundrecimg class="p-5 lightblue-bg"></roundrecimg>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </b-container>-->

    <!--  </div>-->

    <div class="section white-bg py-xl-5 mt-5">
      <div class="p-xl-0 pt-2">
        <b-container>
          <subtitle class="deepblue" subtitle="产品亮点"></subtitle>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium data-aos="fade-right"></separatormedium>
      </div>
      <thesectionrev
        thetitle="智能材料卡片开发"
        thedetails="材料卡片全流程自动化标定，参数自学习迭代寻优，大幅提升材料建模及仿真生产力，只需两步便捷操作，卡片标定快且准"
        theillustration="autocard.png"
      ></thesectionrev>
    </div>

    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium data-aos="fade-right"></separatormedium>
      </div>
      <thesection
        head="集成多种材料模型，复杂工况下仿真精度达到90%以上"
        :detail="conDetails"
        theIllustration="matmodel.png"
      ></thesection>
    </div>

    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium data-aos="fade-right"></separatormedium>
      </div>
      <thesectionrev
        thetitle="满足仿真精度同时，相比同类产品仿真效率提升150%"
        thedetails="以热成形钢A柱落锤冲击试验为例，MatSolver仿真时间仅需5分52秒，同类产品则需15分钟以上。"
        theillustration="liangdianlogo.png"
      ></thesectionrev>
    </div>

    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium data-aos="fade-right"></separatormedium>
      </div>
      <thesection
        head="丰富且持续更新的材料数据库，支撑高精度高效率仿真及选材分析"
        detail="涵盖LA、DP、DH、QP、PHS主流钢种，便捷易用的材料卡片储存和管理工具，有力支撑选材对比分析"
        theIllustration="shujuku.png"
      ></thesection>
    </div>

    <div class="section white-bg">
      <thefooter></thefooter>
    </div>

    <getstartedModal></getstartedModal>
    <callustodayModal></callustodayModal>

    <div class="theToggle">
      <toogle></toogle>
    </div>
  </div>
</template>

<script>
import callustodayModal from '../components/elements/callus.vue';
import thefooter from '../components/elements/footer.vue';
import thesection from '../components/elements/section.vue';
import thesectionrev from '../components/elements/sectionrev.vue';
import getstartedModal from '../components/elements/subscribe.vue';
import theheader from '../components/elements/theheader.vue';
import toogle from '../components/elements/toggleMob.vue';
import separatormedium from '../components/typography/separatormedium.vue';
import subtitle from '../components/typography/subtitle.vue';
// import detailing from "../components/typography/detailing";
// import roundrecimg from "../components/images/roundrecimg";

export default {
  name: 'home',
  data() {
    return {
      conDetails:
        '本构模型：Von Mises, Hill48, Yld89, Yld91, Yld2000-2d, Yld2004r, Ani-Drucker\n断裂模型：MMC, GISSMO, DIEM, JC',
    };
  },
  components: {
    theheader,
    // detailing,
    // roundrecimg,
    thesectionrev,
    thesection,
    toogle,
    subtitle,
    separatormedium,
    thefooter,
    getstartedModal,
    callustodayModal,
  },
};
</script>

<style scoped>
.card-icon-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.card-icon {
  font-size: 1.5vw;
}
@media screen and (max-width: 1400px) {
  .card-icon-circle {
    width: 80px;
    height: 80px;
  }
  .card-icon {
    font-size: 3.2vw;
  }
}
@media screen and (max-width: 1200px) {
  .card-icon-circle {
    width: 80px;
    height: 80px;
  }
  .card-icon {
    font-size: 3.2vw;
  }
}
@media screen and (max-width: 1024px) {
  .card-icon-circle {
    width: 120px;
    height: 120px;
  }
  .card-icon {
    font-size: 3.8em;
  }
}
@media screen and (max-width: 780px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 3vw;
  }
}
@media screen and (max-width: 480px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 380px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 320px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
</style>

<template>
  <div>
    <div class="fixed-top">
      <theheader></theheader>
    </div>

    <div class="section white-bg py-xl-5 mt-5">
      <div class="p-xl-0 pt-2">
        <b-container>
          <subtitle class="deepblue" subtitle="解决方案"></subtitle>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>

      <thesectionCommon
        thetitle="A柱抗冲击性能预测"
        thedetails="基于MatFavor一站式开发热成形钢材料卡片，准确预测A柱抗冲击性能，综合精度超过90%。"
        url="case1.png"
      />
    </div>

    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesectionCommon
        thetitle="25%小偏置碰撞安全仿真"
        thedetails="针对子系统小偏置碰撞工况，基于MatFavor开发刹车盘、车轮、副车架等十余种零部件的材料卡片,准确预测各零部件的变形与断裂行为，预测精度显著优于传统材料卡片。"
        url="case2.png"
        isreverse="1"
      />
    </div>

    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesectionCommon
        thetitle="整车碰撞性能预测"
        thedetails="针对整车侧柱碰、MPDB工况，基于MatFavor开发各主要受力结构件的材料卡片，实现碰撞位置门槛梁局部变形、局部焊点开焊、前纵梁折弯等行为的准确预测，计算效率显著优于同类产品。"
        url="case3.png"
      />
    </div>

    <div class="section">
      <thefooter></thefooter>
    </div>

    <getstartedModal></getstartedModal>

    <div class="theToggle">
      <toogle></toogle>
    </div>
  </div>
</template>

<script>
import thefooter from '../components/elements/footer.vue';
import thesectionCommon from '../components/elements/section-common.vue';
import getstartedModal from '../components/elements/subscribe.vue';
import theheader from '../components/elements/theheader.vue';
import toogle from '../components/elements/toggleMob.vue';
import separatormedium from '../components/typography/separatormedium';
import subtitle from '../components/typography/subtitle.vue';

export default {
  name: 'home',
  components: {
    theheader,
    toogle,
    subtitle,
    separatormedium,
    thefooter,
    getstartedModal,

    thesectionCommon,
  },
};
</script>

<style>
.card-icon-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
</style>

<template>
  <div>
    <div class="fixed-top">
      <theheader></theheader>
    </div>

    <div class="section white-bg py-xl-5 mt-5">
      <div class="p-xl-0 pt-2">
        <b-container>
          <subtitle class="deepblue" subtitle="技术服务"></subtitle>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesectionrev
        thetitle="新型材料研发服务"
        thedetails="研发的1500MPa级热成形钢LummiSword,可进行无填丝自熔激光拼焊，加热时表面形成富Si、Cr、稀土元素的微米级致密氧化 保护膜，可实现无抛丸工艺下热成形零件的直接涂装。"
        theillustration="lumisword.png"
      ></thesectionrev>
    </div>

    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesection
        head="仿真分析技术服务"
        detail="研发的国内首款数智化材料建模系统MatFavor，可实现材料卡片开发周期从20天缩短到2天，能快速响应主机厂及材料厂的卡片开发需求，铸铝、塑料、玻璃等特殊材料仿真精度提高超过20%，同时也可为客户提供定制化仿真分析技术服务。"
        theIllustration="caeserve.png"
      ></thesection>
    </div>

    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesection
        head="材料动态力学性能测试及表征服务"
        detail="深耕材料力学性能、断裂性能、疲劳性能测试，具备较齐全材料测试设备和丰富的测试经验，主要服务于知名企业及科研院校的测试需求，尤其在CAE相关的输入参数及失效验证方面，可为客户提供一揽子解决方案。"
        theIllustration="caseimage.png"
      ></thesection>
    </div>

    <div class="section white-bg">
      <thefooter></thefooter>
    </div>

    <getstartedModal></getstartedModal>
    <callustodayModal></callustodayModal>

    <div class="theToggle">
      <toogle></toogle>
    </div>
  </div>
</template>

<script>
import callustodayModal from '../components/elements/callus.vue';
import thefooter from '../components/elements/footer.vue';
import thesection from '../components/elements/section.vue';
import thesectionrev from '../components/elements/sectionrev.vue';
import getstartedModal from '../components/elements/subscribe.vue';
import theheader from '../components/elements/theheader.vue';
import toogle from '../components/elements/toggleMob.vue';
import separatormedium from '../components/typography/separatormedium.vue';
import subtitle from '../components/typography/subtitle.vue';

export default {
  name: 'home',
  components: {
    theheader,
    thesectionrev,
    thesection,
    toogle,
    subtitle,
    separatormedium,
    thefooter,
    getstartedModal,
    callustodayModal,
  },
};
</script>

<style scoped>
.card-icon-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.card-icon {
  font-size: 1.5vw;
}
@media screen and (max-width: 1400px) {
  .card-icon-circle {
    width: 80px;
    height: 80px;
  }
  .card-icon {
    font-size: 3.2vw;
  }
}
@media screen and (max-width: 1200px) {
  .card-icon-circle {
    width: 80px;
    height: 80px;
  }
  .card-icon {
    font-size: 3.2vw;
  }
}
@media screen and (max-width: 1024px) {
  .card-icon-circle {
    width: 120px;
    height: 120px;
  }
  .card-icon {
    font-size: 3.8em;
  }
}
@media screen and (max-width: 780px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 3vw;
  }
}
@media screen and (max-width: 480px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 380px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 320px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
</style>

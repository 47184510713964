<template>
  <div class="px-4 mx-4">
    <div class="d-flex justify-content-center">
      <b-row align-v="center">
        <b-col>
          <div class="px-xl-5 mx-xl-5 pt-5 mt-5">
            <div class="text-left">
              <div class="main-caption deepblue mb-3">
                <h1 class="deepblue" data-aos="fade-up">
                  {{ maincap1 }} <span class="blue">{{ maincap2 }}</span>
                  {{ maincap3 }}
                </h1>
              </div>
              <div class="subcaption deepblue my-3 py-3">
                <!--                                <h3 class="deepblue" data-aos="fade-up" data-aos-delay="200" v-html="subcaption.replace(/\n/g, '<br>')"></h3>-->
                <detailing
                  class="deepblue mx-xl-2"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  :detailing="subcaption"
                ></detailing>
              </div>
              <!-- <div class="start-btn" id="startBtn">
                <b-button
                  class="bluegrad-bg px-lg-5 px-md-4 py-md-2"
                  href="https://bit.edu.cn/xww/lgxb21/230b0c50ff3a4cf6b0991d549a3f1249.htm"
                  target="_blank"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  ><small class="btntext white">{{ btntext }}</small></b-button
                >
              </div> -->
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="px-xl-5 mx-xl-5">
            <div class="illustration" data-aos="fade-up" data-aos-delay="400">
              <img
                data-rellax-speed="-0.1"
                :src="require('../../assets/' + illustration)"
                alt=""
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import detailing from '../typography/detailing';

export default {
  props: [
    'maincap1',
    'maincap2',
    'maincap3',
    'subcaption',
    'btntext',
    'illustration',
  ],
  components: {
    detailing,
  },
};
</script>

<style scoped>
.getstarted {
  position: relative;
  z-index: 8;
}
.subcaption h3 {
  font-size: 22px;
  font-weight: 300;
}
.main-caption h1 {
  font-size: 48px;
  font-weight: 800;
}
.btntext {
  font-size: 18px;
  font-family: 'Microsoft Yahei', sans-serif;
}
.illustration {
  position: relative;
  height: 780px;
  display: flex;
  align-items: center;
}
.illustration img {
  position: relative;
  top: 100px;
  width: 100%;
  max-width: 780px;
  min-width: 580px;
}
@media screen and (max-width: 1024px) {
  .sub-caption h5 {
    font-size: 3vw;
  }
  .submaincaption h3 {
    font-size: 5vw;
  }
  .main-caption h1 {
    font-size: 6vw;
  }
}

@media screen and (max-width: 780px) {
  .sub-caption h5 {
    font-size: 3vw;
  }
  .submaincaption h3 {
    font-size: 4vw;
  }
  .main-caption h1 {
    font-size: 5vw;
  }
}

@media screen and (max-width: 480px) {
  .subcaption h3 {
    font-size: 4.8vw;
  }
  .main-caption h1 {
    font-size: 7vw;
  }
  .illustration {
    height: 380px;
  }
  .illustration img {
    position: relative;
    top: 20px;
    min-width: 320px;
  }
}
@media screen and (max-width: 380px) {
  .subcaption h3 {
    font-size: 4.8vw;
  }
  .main-caption h1 {
    font-size: 7vw;
  }
  .illustration {
    height: 360px;
  }
  .illustration img {
    position: relative;
    top: 20px;
    min-width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .subcaption h3 {
    font-size: 5vw;
  }
  .main-caption h1 {
    font-size: 7vw;
  }
  .illustration {
    height: 260px;
  }
  .illustration img {
    top: 20px;
    min-width: 240px;
  }
}
</style>
